import Auth from '../../../src/components/Auth/Auth';

const widget = new Auth();
window.authWidgetCover = widget
widget.setEndpointUrl(window.location.protocol + '//' + window.location.host + '/auth-user')
document.addEventListener('DOMContentLoaded', () => {
    widget.init().then(() => {
        function addLoginListener(buttonId) {
            const button = document.getElementById(buttonId);
            if (button) {
                button.addEventListener('click', () => {
                    if (widget.isLoggedIn()) {
                        console.log('user already logged');
                        return;
                    }
                    widget.showLogin();
                }, false);
            }
        }

        addLoginListener('login-button');
        addLoginListener('login-button-article');

        let elements = document.getElementsByClassName('js-pay_course_pre_auth');
        for (let i = 0; i < elements.length; i++) {
            elements[i].addEventListener('click', () => {
                setCookie('assignCourseAfterLogin', 'true', 1);
                if (widget.isLoggedIn()) {
                    return;
                }

                widget.showLogin();
            }, false);
        }

        if (document.getElementById('reg_button')) {
            document.getElementById('reg_button').addEventListener('click', () => {
                if (widget.isLoggedIn()) {
                    console.log('user already logged')
                    return;
                }

                widget.showRegistration();
            }, false);
        }

        if (document.getElementById('re_data_profile')) {
            document.getElementById('re_data_profile').addEventListener('click', () => {
                if (!widget.isLoggedIn()) {
                    console.log('user not login in ')
                    return;
                }

                widget.showSettings();
            }, false);
        }



        let logoutButtons = document.getElementsByClassName('logout-button');

        if (logoutButtons.length) {
            window.logoutUrl = logoutButtons[0].getAttribute('href');
            Array.from(logoutButtons).forEach((logoutButton) => {
                logoutButton.addEventListener('click', (e) => {
                    e.preventDefault();
                    widget.showLogout();
                });
            });
        }

        if (findGetParameter('auth') === '1') {
            widget.showLogin();
        }
    });

    function setCookie(name,value,days) {
        let expires = '';
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + (value || '')  + expires + '; path=/';
    }

    function findGetParameter(parameterName) {
        var result = null,
            tmp = [];
        window.location.search
            .substring(1, window.location.search.length)
            .split('&')
            .forEach(function (item) {
                tmp = item.split('=');
                if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
            });
        return result;
    }
});




